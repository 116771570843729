<template>
  <div >

     <router-view></router-view>
     <!-- <inventory-inquiry-view></inventory-inquiry-view> -->
    <!-- <login-view></login-view>  -->
    <!-- <department-view></department-view> -->
  </div>
</template>

<script>
import InventoryInquiryView from './views/query/InventoryInquiryView.vue';
// import DepartmentView from './views/system/DepartmentView.vue';
// import LoginView from './views/login/LoginView.vue';





export default {
  components: { InventoryInquiryView },
  // components: { DepartmentView },
  // components: { LoginView },
    data() {
        return {};
    },

}
</script>

<style>
  /**
为全局自定义导入的彩色iconfont设置属性
 */
.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    font-size: 48px;
  }
</style>
