import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name: 'Login',
    component: ()=> import( '../views/login/LoginView.vue')
  },
  {
    path: '/Department',
    name: 'Department',
  component: ()=> import( '../views/system/DepartmentView.vue')
  },
  {
    path: '/User',
    name: 'User',
  component: ()=> import( '../views/system/UserView.vue')
  },
  {
    path: '/Warehouse',
    name: 'Warehouse',
  component: ()=> import( '../views/system/WarehouseView.vue')
  },
  {
    path: '/Supplier',
    name: 'Supplier',
  component: ()=> import( '../views/system/SupplierView.vue')
  },
  {
    path: '/Personal',
    name: 'Personal',
  component: ()=> import( '../views/system/PersonalView.vue')
  },  
  {
    path: '/ApplicationForm',
    name: 'ApplicationForm',
  component: ()=> import( '../views/UserApplicationForm/ApplicationFormView.vue')
  },  
  {
    path: '/Platform',
    name: 'Platform',
  component: ()=> import( '../views/platform/PlatformView.vue'),
  }, 
  {
    path: '/InventoryInquiry',
    name: 'InventoryInquiry',
  component: ()=> import( '../views/query/InventoryInquiryView.vue')
  },
  {
    path: '/Inventory',
    name: 'Inventory',
  component: ()=> import( '../views/query/InventoryView.vue')
  },
  {
    path: '/Deposit',
    name: 'Deposit',
  component: ()=> import( '../views/deposit/DepositView.vue')
  },
  {
    path: '/Release',
    name: 'Release',
  component: ()=> import( '../views/release/ReleaseView.vue')
  },
  {
    path: '/NewRelease',
    name: 'NewRelease',
  component: ()=> import( '../views/release/NewReleaseView.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
