<template>
    <el-container style="height: 900px; border: 1px solid #eee">
        <el-aside width="250px" style="background-color:#545c64">
            <h1>抢单大厅后台管理系统</h1>
            <el-menu :default-opened="['1', '6']" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
            <el-menu-item index="5-1">
                <router-link to="/Platform"><i>
                    <IconSvg icon-style="icon" icon-class="icon-lujing-18" style="font-size: 24px; margin-right: 18px" />
                </i>平台</router-link>
            </el-menu-item>
            <el-menu-item index="6-1">
                <router-link to="/Deposit"><i>
                    <IconSvg icon-style="icon" icon-class="icon-money-finance-seller-fill" style="font-size: 24px; margin-right: 18px" />
                </i>押金管理</router-link>
            </el-menu-item>
            <el-menu-item index="1">
                <router-link to="/Release"><i>
                    <IconSvg icon-style="icon" icon-class="icon-order-rejected" style="font-size: 24px; margin-right: 18px" />
                </i>发布信息</router-link>
            </el-menu-item>
            <el-menu-item index="7">
                <router-link to="/NewRelease"><i>
                    <IconSvg icon-style="icon" icon-class="icon-order-rejected" style="font-size: 24px; margin-right: 18px" />
                </i>新增发布信息</router-link>
            </el-menu-item>
            <el-menu-item index="2-1">
                <router-link to="/ApplicationForm"><i>
                    <IconSvg icon-style="icon" icon-class="icon-suggest" style="font-size: 24px; margin-right: 15px" />
                </i>信誉管理</router-link>
            </el-menu-item>   
            <el-submenu index="3" >
                <!-- eslint-disable-next-line -->
                <template slot="title">
                    <IconSvg icon-style="icon" icon-class="icon-lujing-17" style="font-size: 24px; margin-right: 18px" />
                系统设置</template>
                <el-menu-item-group>
                <el-menu-item index="3-1"  v-if="user.roleId != 2 && user.roleId !=3 && user.roleId !=4">
                        <router-link to="/Department"><i>
                            <IconSvg icon-style="icon" icon-class="icon-money-bank-fill" style="font-size: 24px; margin-right: 15px" />
                        </i>用人单位</router-link>
                </el-menu-item>
                <el-menu-item index="3-2" v-if="user.roleId != 2 && user.roleId !=3 && user.roleId !=4">
                    <router-link to="/User" ><i>
                        <IconSvg icon-style="icon" icon-class="icon-customer-management" style="font-size: 24px; margin-right: 15px" />
                    </i>行业分类</router-link>
                </el-menu-item>
                </el-menu-item-group>
                <el-menu-item index="3-3" v-if="user.roleId != 2 && user.roleId !=3">
                    <router-link to="/Warehouse" ><i>
                        <IconSvg icon-style="icon" icon-class="icon-store" style="font-size: 24px; margin-right: 15px" />
                    </i>微信用户信息</router-link>
                </el-menu-item>
                <el-menu-item index="3-4">
                    <router-link to="/Supplier" ><i>
                        <IconSvg icon-style="icon" icon-class="icon-shengyidai" style="font-size: 24px; margin-right: 15px" />
                    </i>工种分类</router-link>
                </el-menu-item>
            </el-submenu>
            <el-submenu index="4" >
                <!-- eslint-disable-next-line -->
                <template slot="title"><i>
                    <IconSvg icon-style="icon" icon-class="icon-lujing-6" style="font-size: 24px; margin-right: 18px" />
                </i>查询统计</template>
                <el-menu-item index="4-1">
                        <router-link to="InventoryInquiry"><i>
                            <IconSvg icon-style="icon" icon-class="icon-search" style="font-size: 24px; margin-right: 18px" />
                        </i>抢单明细</router-link>
                </el-menu-item>
                <el-menu-item index="4-2">
                        <router-link to="Inventory"><i>
                            <IconSvg icon-style="icon" icon-class="icon-search" style="font-size: 24px; margin-right: 18px" />
                        </i>已完成发布单查询</router-link>
                </el-menu-item>
            </el-submenu>
            <el-menu-item index="5-1">
                <router-link to="/Personal"><i> 
                    <IconSvg icon-style="icon" icon-class="icon-customer" style="font-size: 24px; margin-right: 18px" />
                </i>个人中心</router-link>
            </el-menu-item>
            </el-menu>
        </el-aside>
        <el-container>
            <el-header>
                <div class="header-container">
                    <div class="l-content">
                       <span id="a"> |  抢单明细</span>
                    </div>
                    <div class="r-content">
                        <el-dropdown @command="handleClick">
                            <span class="el-dropdown-link">
                                <img class="user" src="../../assets/小男孩.jpg"/>
                            </span>
                            <!-- eslint-disable-next-line -->
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>个人信息</el-dropdown-item>
                                <el-dropdown-item command="cancel">退出</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </el-header>
            <el-main>
                <el-form :inline="true" :model="formInline" style="float:left">
                    <el-form-item label="发布编号">
                        <el-input v-model="formInline.releaseNumber" placeholder="发布编号"></el-input>
                    </el-form-item>   
                    <el-form-item label="用户姓名">
                        <el-input v-model="formInline.wxName" placeholder="用户姓名"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="selectWarehousingId()">查询</el-button>
                    </el-form-item>
                </el-form>
                <template>
                    <el-table  :data="tableData"  ref="multipleTable"  border  style="width: 100%" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
                        <el-table-column   type="selection"  row-key="warehousingId"   width="55"></el-table-column>
                        <el-table-column property="releaseNumber" label="发布编号" ></el-table-column>
                        <el-table-column property="wxName" label="用户姓名" ></el-table-column>
                        <el-table-column property="phone" label="用户手机号" ></el-table-column>
                        <el-table-column property="createTime" label="发布时间" ></el-table-column>
                        <el-table-column property="publishStatus" label="发布状态" ></el-table-column>
                    </el-table>
                </template>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import axios from 'axios';
import IconSvg from "../../common/IconSvg";
export default {
    components: {IconSvg},
    data() {
        return {
            user:JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getItem('token').split(".")[1])))),
            tableData:[],
            formInline:{
                releaseNumber:'',
                wxName:''
            },
        }   
    },
    created(){
    	//页面加载时就从本地通过localstorage获取存储的token值
        this.token =  localStorage.getItem('token')
        this.ken =  localStorage.getItem('token') .split(".")
            let str=this.ken[1];
            let id=JSON.parse(decodeURIComponent(escape(window.atob(str))));//获取token的值
        },//读取token本地信息以及解析touken中的值
    mounted(){
        document.title='抢单大厅后台管理系统'
        this.list()
    },
    methods: {
        list(){
            axios({
                method: 'get',
            	url: 'http://47.97.84.243:8083/admin/statistics',
        	    headers: {
            	//把token放到请求头才能请求，这里的'Bearer '表示是后台希望更加的安全，依据后台给的信息看到底是加还是不加
            	'token':  this.token,
        	    }
                }).then((result) => {
                    if(result.data.code===1){
                        this.tableData=result.data.data;
                        this.$message({
                        message: '查询成功！！！',
                        type: 'success'
                        });
                    }else{
                        this.$message.error(result.data.msg)
                    }
                
                })
        },//查看入库明细
        selectWarehousingId(){
            axios({
                method: 'post',
            	url: 'http://47.97.84.243:8083/admin/statistics',
                data:this.formInline,
        	    headers: {
            	//把token放到请求头才能请求，这里的'Bearer '表示是后台希望更加的安全，依据后台给的信息看到底是加还是不加
            	'token':  this.token,
        	    }
                }).then((result) => {
                    if(result.data.code===1){
                        this.tableData=result.data.data;
                        this.$message({
                        message: '查询成功！！！',
                        type: 'success'
                        });
                    }else{
                        this.$message.error(result.data.msg)
                    }
                
                })
        },
        handleClick(command){
            if(command ==='cancel'){
                localStorage.removeItem('token')
                //跳转到登陆界面
                this.$router.push('/')
            }
        }
    }
    
}
</script>

<style scope>
    #a{
        color: white;
    }
    .router-link-active{
        text-decoration: none;
        color: #ffd04b;
    }
    a{
        text-decoration: none;
        color: white;
    }
    .header-container {
        background-color: #333;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
    }           
    .user {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    h1{
        text-align: center;
        line-height: 48px;
        color: #fff;
        font-size: 16px;
        font-weight: 250px;
    }
    .el-header, .el-footer {
    background-color: #333;
  }
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
</style>