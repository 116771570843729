import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel)
import './router/iconfont.js'
import 'default-passive-events';

Vue.config.productionTip = false
Vue.use(ElementUI);
//添加全局前置导航守卫，防止没有token还能进行访问菜单
router.beforeEach((to, from, next) => {
  // 判断token存不存在
  const token=localStorage.getItem('token')
  //token不存在。说明当前用户未登录，应该跳转到登录页
  if(!token && to.name !== 'Login'){
    next({name:'Login'})
  }else if( token && to.name ==='Login'){
    //token存在，应该跳转到首页
    next({name:'Platform'})
  }else{
    next();
  }
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
